import { vsDark } from "@code-surfer/themes";

const staffBlue = "#232d41";

export default {
  ...vsDark,
  colors: {
    background: staffBlue,
    text: "white",
    primary: "white"
  },
  styles: {
    ...vsDark.styles,
    CodeSurfer: {
      ...vsDark.styles.CodeSurfer,
      pre: {
        ...vsDark.styles.CodeSurfer.pre,
        backgroundColor: staffBlue
      },
      code: {
        ...vsDark.styles.CodeSurfer.code,
        backgroundColor: staffBlue
      },
      title: {
        backgroundColor: staffBlue,
        color: "white"
      },
      subtitle: {
        color: "white",
        backgroundColor: staffBlue
      },
    }
  }
};