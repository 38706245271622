import { Image, Split, Appear, Horizontal } from 'mdx-deck';
import { CodeSurfer, CodeSurferColumns, Step } from "code-surfer";
import "prismjs/components/prism-kotlin";
import breakOutTheme from "../../../../../../Theme.js";
import logo from "../../../../../../static/images/logo.png";
import croppedLogo from "../../../../../../static/images/logo-cropped.png";
import wtf from "../../../../../../static/images/wtf.png";
import header from "../../../../../../static/images/header.jpg";
import map from "../../../../../../static/images/map.png";
import kotlin from "../../../../../../static/images/kotlin.png";
import app from "../../../../../../static/images/app.png";
import web from "../../../../../../static/images/web.png";
import code from "../../../../../../static/images/code.jpg";
import React from 'react';
export default {
  Image: Image,
  Split: Split,
  Appear: Appear,
  Horizontal: Horizontal,
  CodeSurfer: CodeSurfer,
  CodeSurferColumns: CodeSurferColumns,
  Step: Step,
  breakOutTheme: breakOutTheme,
  logo: logo,
  croppedLogo: croppedLogo,
  wtf: wtf,
  header: header,
  map: map,
  kotlin: kotlin,
  app: app,
  web: web,
  code: code,
  React: React
};